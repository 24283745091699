<template>
  <div class="m_page_contianer_all">
    <JoinusHeader :item="info.header" />
    <NewsPage :items="info.content" />
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import JoinusHeader from "./SubDiv/Header.vue";
import NewsPage from "@/components/Mobile/NewsPage.vue";
import { kJoinusPageContentInfo } from "@/config/joinus";

@Options({
  components: {
    JoinusHeader,
    NewsPage,
  },
  data() {
    return {
      info: kJoinusPageContentInfo,
    };
  },
})
export default class JoinusPage extends Vue {}
</script>

<style>
@import url("../../../less/mobile/page.less");
</style>