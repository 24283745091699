<template>
  <div id="content">
    <div id="title_content">
      <div
        v-for="(des, index) in this.$props.item.content"
        :key="index"
        id="des"
      >
        {{ des }}
      </div>
    </div>
    <div id="qrCode_content">
      <img id="qrCode_img" src="@/assets/qrcode.png" />
      <div id="qrCode_des_content">
        <img id="qrCode_des_icon" src="@/assets/wechat.png" />
        <div id="qrCode_des">扫码上方二维码关注我们</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    item: Object,
  },
  data() {
    return {};
  },
})
export default class JoinusHeader extends Vue {}
</script>

<style scoped lang="scss">
@import url("../../../../less/pc/page.less");
#content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
}

#title_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(80 / 1080 * 100vw);
  margin-bottom: calc(80 / 1080 * 100vw);
  width: 80%;
}

#des {
  font-size: calc(30 / 1080 * 100vw);
  color: #666666;
  align-self: center;
  text-align: center;
}

#qrCode_content {
  display: flex;
  flex-direction: column;
  margin-bottom: calc(80 / 1080 * 100vw);
}

#qrCode_img {
  width: calc(360/1080 * 100vw);
  height: calc(360/1080 * 100vw);
}

#qrCode_des_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#qrCode_des_icon {
  width: calc(32 / 1080 * 100vw);
  height: calc(32 / 1080 * 100vw);
}

#qrCode_des {
  color: #999999;
  font-size: calc(28 / 1080 * 100vw);
}
</style>